.App {
  text-align: center;
}
.App p {
  text-align: left;
  padding-left: 10px;
  margin: 10px;
}
.slick-slide {
  width:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-logo2 {
  height: 10vmin;
  pointer-events: none;
}

.section2 {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.section2 p {
  margin-bottom: 1em;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  align-self: flex-start;
}
.section3 {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.section3 p {
  margin-bottom: 1em;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  align-self: flex-start;
}
.section4 {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.section5 p {
  margin-bottom: 1em;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  align-self: flex-start;
}
.section5 a {
  color: #61dafb;
  text-decoration: none;
}
.section5 {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.section5 p {
  margin-bottom: 1em;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  align-self: flex-start;
}
.section5 a {
  color: #61dafb;
  text-decoration: none;
}
.App-header {
  background-color: #081113;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-footer {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding-bottom:5px;
}

.App-link {
  color: #61dafb;
}
.App-banner {
  background-color: #081113;
  padding:0px;
  min-height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-bottom:5px;
}
.App-banner2 {
  background-color: #081113;
  padding:0px;
  width:100%;
  min-height: 10vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-bottom:5px;
  width:100%;
}
